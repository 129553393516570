'use strict';

class fischer {

    constructor() {

    }

    static showStatusTextOrange(d){
        return `<span class="orange">${d.status_text}</span>`
    }
}

window.fischer = fischer;


