'use strict';

class AppSystem {

    constructor() {

    }

    static updateFormDataFile(route, data, method = 'post', success_callback = null) {
        let index = layer.msg('提交修改中', {icon: 7, time: 0, shade: 0.3});
        let formData = new FormData();
        formData.append('file', $('#file')[0].files[0]);
        axios({
            method: method,
            url: route,
            data: formData,
        })
            .then(function (res) {
                setTimeout(function () {
                    layer.close(index);
                    if (res.data.status === 200) {
                        layer.msg('更新成功', {icon: 1});
                        if (success_callback) {
                            success_callback(post_data);
                        }
                    } else {
                        layer.msg(res.data.message, {icon: 2});
                    }
                }, 500)
            })
            .catch(function (error) {
                layer.close(index);
                layer.msg('504错误,更新失败!');
            });
        return false;
    }

    //更新系统
    static updateData(route, data, method = 'post', success_callback = null, serialize_element = null) {
        let index = layer.msg('提交修改中', {icon: 7, time: 0, shade: 0.3});
        let post_data = data.field;
        if (serialize_element) {
            let $ = layui.jquery;
            post_data = $(serialize_element).serialize();
        }
        axios({
            method: method,
            url: route,
            data: post_data,
        })
            .then(function (res) {
                setTimeout(function () {
                    layer.close(index);
                    if (res.data.status === 200) {
                        layer.msg('更新成功', {icon: 1});
                        if (success_callback) {
                            success_callback(post_data);
                        }
                    } else {
                        layer.msg(res.data.message, {icon: 2});
                    }
                }, 500)
            })
            .catch(function (error) {
                layer.close(index);
                layer.msg('504错误,更新失败!');
            });
        return false;
    }

    static deleteData(route, data = {}, method = 'delete', success_callback = null) {
        let index = layer.msg('删除中...', {icon: 7, time: 0, shade: 0.3});
        data._method = data._method ? data._method : method;
        axios.post(route, data)
            .then(function (res) {
                setTimeout(function () {
                    layer.close(index);
                    if (res.data.status === 200) {
                        layer.msg('删除成功', {icon: 1});
                        if (success_callback) {
                            success_callback(data);
                        }
                    } else {
                        layer.msg(res.data.message, {icon: 2});
                    }
                }, 500)
            })
            .catch(function (error) {
                layer.close(index);
                layer.msg('504错误,删除失败!');
            });
        return false;
    }

    static generateBarcode(route, data = {}, method = 'post', success_callback = null) {
        let index = layer.msg('生成中...', {icon: 7, time: 0, shade: 0.3});
        data._method = data._method ? data._method : method;
        axios.post(route, data)
            .then(function (res) {
                setTimeout(function () {
                    if (res.data.status === 200) {
                        layer.close(index);
                        layer.msg('生成成功', {icon: 1});
                        if (success_callback) {
                            success_callback(data);
                        }
                    } else {
                        layer.close(index);
                        layer.msg(res.data.message, {icon: 2});
                    }
                }, 500)
            })
            .catch(function (error) {
                layer.close(index);
                layer.msg('504错误,生成失败!');
            });
        return false;
    }

    static multiUpload({
                           element_select = '#upload-select-btn',
                           element_action = '#upload-action-btn',
                           element_view = '#upload-view-list',
                           view_item_prefix = 'upload-index-',
                           form_submit_name = 'upload[]',
                           return_data_key = 'src',
                           field = 'file',
                           size = 4000,
                           url = '/upload',
                           del_url = '/delete',
                           data = {},
                           accept = 'images',
                           multiple = true,
                           auto = false,
                           number = 3,
                           drag = false,
                           exts = 'jpg|png|gif|bmp|jpeg',
                       } = {},) {
        let $ = layui.jquery;
        let upload = layui.upload;
        // let layer = layui.layer;
        let uploadListView = $(element_view);
        data.field = data.field ? data.field : field;//初始化data.field
        let uploadListIns = upload.render({
            elem: element_select.toString(),
            url: url.toString(),
            accept: accept.toString(),
            data: data,
            multiple: multiple,
            auto: auto,
            field: field,
            size: size,
            number: number,
            exts: exts,
            drag: drag,
            bindAction: element_action.toString(),
            choose: function (obj) {
                let files = this.files = obj.pushFile(); //将每次选择的文件追加到文件队列
                //读取本地文件
                obj.preview(function (index, file, result) {
                    let show_item = '';
                    if (accept.toString() === 'images') {
                        show_item = `
                            <img src="${result}" alt="${file.name}" class="layui-upload-img">
                        `
                    } else {
                        show_item = file.name
                    }
                    let tr = $(`
                        <tr id="${view_item_prefix}${index}">
                            <td>${show_item}</td>
                            <td>${(file.size / 1014).toFixed(1)}kb</td>
                            <td>等待上传</td>
                            <td>
                                <button class="layui-btn layui-btn-xs upload-reload-item-btn layui-hide">重传</button>
                                <button class="layui-btn layui-btn-xs upload-delete-item-btn layui-btn-danger">取消</button>
                            </td>
                        </tr>
                    `);
                    //单个重传
                    tr.find('.upload-reload-item-btn').on('click', function (e) {
                        e.preventDefault();
                        obj.upload(index, file);
                    });
                    //删除
                    tr.find('.upload-delete-item-btn').on('click', function () {
                        delete files[index]; //删除对应的文件
                        tr.remove();
                        uploadListIns.config.elem.next()[0].value = ''; //清空 input file 值，以免删除后出现同名文件不可选
                    });
                    uploadListView.append(tr);
                });
            },
            done: function (res, index, upload) {
                if (res) {
                    if (res.status === 200) { //上传成功
                        let tr = uploadListView.find('tr#' + view_item_prefix + index)
                            , tds = tr.children();
                        tds.eq(2).html('<span style="color: #5FB878;">上传成功</span>');
                        // tds.eq(3).html(''); //清空操作并添加input hidden元素
                        let replace_element = $(`
                            <div class="upload-operation">
                                <input type="button" class="layui-btn layui-btn-xs upload-delete-item layui-btn-danger" value="删除">
                                <input type="hidden" name="${form_submit_name}" value="${res.data[return_data_key]}">
                            </div>
                        `);
                        replace_element.find('.upload-delete-item').on('click', function (e) {
                            e.preventDefault();
                            let src = replace_element.find("input[type='hidden']").val();
                            AppSystem.deleteFile(del_url.toString(), src, function (data) {
                                let tr = uploadListView.find('tr#' + view_item_prefix + index);
                                tr.remove();
                            });
                        });
                        tds.eq(3).html(''); //清空操作并添加input hidden元素
                        tds.eq(3).append(replace_element); //清空操作并添加input hidden元素
                        return delete this.files[index]; //删除文件队列已经上传成功的文件
                    } else {
                        layer.msg(res.message ? res.message : '上传失败', {icon: 2});
                    }
                }
                this.error(index, upload);
            },
            error: function (index, upload) {
                let tr = uploadListView.find('tr#' + view_item_prefix + index)
                    , tds = tr.children();
                tds.eq(2).html('<span style="color: #FF5722;">上传失败</span>');
                tds.eq(3).find('.upload-reload-item-btn').removeClass('layui-hide'); //显示重传
            }
        });
        console.log(uploadListIns)
    }

    static deleteFile(route, src = '', success_callback = null, method = 'delete') {
        let index = layer.msg('删除中...', {icon: 7, time: 0, shade: 0.3});
        let data = {};
        data._method = data._method ? data._method : method;
        data.src = src ? src : '';
        axios.post(route, data)
            .then(function (res) {
                setTimeout(function () {
                    layer.close(index);
                    if (res.data.status === 200) {
                        if (success_callback) {
                            success_callback(res.data);
                        }
                        layer.msg('删除成功', {icon: 1});
                    } else {
                        layer.msg(res.data.message, {icon: 2});
                    }
                }, 500)
            })
            .catch(function (error) {
                layer.close(index);
                layer.msg('504错误,删除失败!');
            });
    }

    static Upload({
                      element_select = "#upload-select-btn",
                      element_file = "#upload-file-name",
                      element_view = "#upload-view",
                      url = '/upload',
                      del_url = '/delete',
                      return_data_key = 'src',
                      form_submit_name = 'upload',
                      accept = 'file',
                      auto = true,
                      field = 'file',
                      data = {},
                      number = 1,
                      drag = false,
                      exts = 'pdf',
                      preview_url = '/storage/uploads/',
                  } = {}, add_origin_name = false) {
        let $ = layui.jquery;
        let upload = layui.upload;
        data.field = data.field ? data.field : field;//初始化data.field
        let uploadInstance = upload.render({
            elem: element_select,
            url: url.toString(),
            accept: accept,
            auto: auto,
            number: number,
            data: data,
            drag: drag,
            exts: exts,
            before: function (obj) {
                if (number === 1) {
                    if ($(element_file).find('a') > 0) {
                        layer.msg('请删除后,重新上传', {icon: 2});
                        return false;
                    }
                }
                //overview not support for ie8
                layer.load(0, {shade: 0.2});
                obj.preview(function (index, file) {
                    let file_name_dom = $(element_file);
                    if (add_origin_name) {
                        file_name_dom.html(`
                            <a target="_blank" title="点击预览" lay-tips="点击预览"><i class="fa fa-eye"></i>${file.name}</a>
                            <input type="hidden" name="origin_name" value="${file.name}">
                        `);
                    } else {
                        file_name_dom.html(`<a target="_blank" title="点击预览" lay-tips="点击预览"><i class="fa fa-eye"></i>${file.name}</a>`);
                    }
                });
            },
            done: function (res, index) {
                if (res) {
                    if (res.status === 200) {
                        let view_parent_dom = $(element_view).parent();
                        let replace_element = $(`
                            <span class="upload-operation">
                                <input type="button" class="layui-btn layui-btn-xs upload-delete-item layui-btn-danger" value="删除">
                                <input type="hidden" name="${form_submit_name}" value="${res.data[return_data_key]}">
                            </span>
                        `);
                        replace_element.find('.upload-delete-item').on('click', function (e) {
                            e.preventDefault();
                            let src = replace_element.find("input[type='hidden']").val();
                            AppSystem.deleteFile(del_url.toString(), src, function (data) {
                                let operation_dom = view_parent_dom.find('.upload-operation');
                                $(element_file).html('');
                                $(element_view).html('');
                                operation_dom.remove();
                            });
                        });
                        $(element_file).find('a').attr('href', preview_url + res.data[return_data_key]).addClass('color-upload-success');
                        $(element_view).html('');
                        view_parent_dom.append(replace_element);
                        layer.msg('上传成功', {icon: 1});
                        layer.closeAll('loading');
                        return;
                    }
                }
                layer.msg(res.message ? res.message : '上传失败', {icon: 2});
                this.error(index);
            },
            error: function (index) {
                let view_dom = $(element_view);
                view_dom.html('<span style="color: #FF5722;">上传失败</span> <a class="layui-btn upload-view-reload layui-btn-sm">重试</a>');
                view_dom.find('.upload-view-reload').on('click', function () {
                    uploadInstance.upload();
                });
                layer.closeAll('loading');
            }
        })
    }
}

window.AppSystem = AppSystem;
