'use strict';

const mi_info = {
    "github_1": "https://github.com/mi-autumn",
    "github_2": "https://github.com/semi-aurora",
    "wechat_1": "mi-autum",
    "email_g_1": 'mi.autumn520@gmail.com',
    "email_g_2": 'mi.autumn520@gmail.com',
    "email_h_1": 'mi-autumn@hotmail.com',
    "email_h_2": 'mi-autumn@hotmail.com',
};

const mi_colors = {
    "gray": "color: #1B2B34;font-size: 12px; line-height: 18px;",
    "red": "color: #EC5f67;font-size: 12px; line-height: 18px;",
    "orange": "color: #F99157;font-size: 12px; line-height: 18px;",
    "yellow": "color: #FAC863;font-size: 12px; line-height: 18px;",
    "green": "color: #99C794;font-size: 12px; line-height: 18px;",
    "teal": "color: #5FB3B3;font-size: 12px; line-height: 18px;",
    "blue": "color: #6699CC;font-size: 12px; line-height: 18px;",
    "purple": "color: #C594C5;font-size: 12px; line-height: 18px;",
    "brown": "color: #AB7967;font-size: 12px; line-height: 18px;"
};

class MiConsole {
    constructor() {

    }

    static consoleImg(url) {
        console.log('%c', 'padding:50px 300px; line-height:120px; background:url(' + url + ') no-repeat;');
    };

    static consoleText(list, label = 'github', info_content = "github_1", mode ='random') {
        switch (mode) {
            case 'random':
                let colorList = [mi_colors.red, mi_colors.orange, mi_colors.yellow, mi_colors.green, mi_colors.teal, mi_colors.blue, mi_colors.purple, mi_colors.brown];
                if(_.isArray(list)){
                    list.forEach(function(i){
                        let str = i.toString();
                        let ind = MiConsole.randomNum(0, colorList.length - 1);
                        console.log('%c ' + str, colorList[ind]);
                    })
                }
                break;
            case 'banner':
                if(_.isArray(list)){
                    list.forEach(function(i){
                        console.log('\n' + ' %c ' + i + ' %c ' + i + ' ' + '\n', 'color: #fadfa3; background: #030307; padding:5px 0;', 'background: #fadfa3; padding:5px 0;');
                    })
                }
                break;
            default:
                if(_.isArray(list)){
                    console.log('%c ' + list.join('\n'), mi_colors.gray);
                }
                break;
        }
        let github_Href = mi_info[info_content] ? mi_info[info_content] : info_content;
        let email_Href = mi_info.email_g_1;
        let github = ["%c %c %c " + label + " %c  %c " + github_Href + "  %c", "background: #fadfa3; padding:5px 0;", "background: #fadfa3; padding:5px 0;", "color: #fadfa3; background: #030307; padding:5px 0;", "background: #fadfa3; padding:5px 0;", "background: #FCEDC9; padding:5px 0;", "background: #fadfa3; padding:5px 0;"];
        let email = ["%c %c %c " + 'E-mail' + " %c  %c " + email_Href + "  %c", "background: #fadfa3; padding:5px 0;", "background: #fadfa3; padding:5px 0;", "color: #fadfa3; background: #030307; padding:5px 0;", "background: #fadfa3; padding:5px 0;", "background: #FCEDC9; padding:5px 0;", "background: #fadfa3; padding:5px 0;"];
        window.console.log.apply(console, github);
        window.console.log.apply(console, email);
    };

    static getRunDate(dateString) {
        dateString = (dateString).toString().split('-');
        let date = new Date();
        date.setUTCFullYear(dateString[0], dateString[1] - 1, dateString[2]);
        date.setUTCHours(0, 0, 0, 0);
        let birthDay = date;
        let today = new Date();
        let timeold = today.getTime() - birthDay.getTime();
        let sectimeold = timeold / 1000;
        let secondsold = Math.floor(sectimeold);
        let msPerDay = 24 * 60 * 60 * 1000;
        let e_daysold = timeold / msPerDay;
        let daysold = Math.floor(e_daysold);
        let e_hrsold = (daysold - e_daysold) * -24;
        let hrsold = Math.floor(e_hrsold);
        let e_minsold = (hrsold - e_hrsold) * -60;
        let minsold = Math.floor((hrsold - e_hrsold) * -60);
        let seconds = Math.floor((minsold - e_minsold) * -60).toString();
        return {
            daysold: daysold,
            hrsold: hrsold,
            minsold: minsold,
            seconds: seconds
        };
    }

    static htmlFiltrationScriptFunction(str) {
        let subStr = new RegExp('\<script.*\<\/script\>', 'ig');
        return str.replace(subStr, "");
    };


    static randomNum(minNum, maxNum) {
        switch (arguments.length) {
            case 1:
                return parseInt(Math.random() * minNum + 1);
            case 2:
                return parseInt(Math.random() * (maxNum - minNum + 1) + minNum);
            default:
                return 0;
        }
    };
}
if (window === top) {
    MiConsole.consoleText(['Author info: mi-autumn Location:Asia/ShangHai','Website:www.mi-autumn.com','Alternate Email:3246152772@qq.com/semi-aurora@hotmail.com']);
}
