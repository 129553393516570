import Vue from 'vue'
//javascript
import ElementUI from 'element-ui'
import XEUtils from 'xe-utils'
import XEAjax from 'xe-ajax'
import VXETable from 'vxe-table'

import VXETablePluginElement from 'vxe-table-plugin-element'
// import VueLocalStorage from 'vue-localstorage'
//css
import 'element-ui/lib/theme-chalk/index.css'
import 'vxe-table/lib/style.css'
import 'vxe-table-plugin-element/dist/style.css'

window.Vue = Vue;
require('./bootstrap.js');
require('./common/consoleInfo.js')

//windows append
window.XEAjax = XEAjax;
window.XEUtils = XEUtils;

//some config
VXETable.use(VXETablePluginElement)
Vue.use(VXETable).use(ElementUI)
// .use(VueLocalStorage)
Vue.config.devtools = true;
Vue.config.productionTip = true;

//use windows
require('./systemModules/system.js');
require('./systemModules/common.js');
